@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Poppins:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Show warning message in portrait mode */
@media screen and (orientation: portrait) {
  .landscape-content {
    display: none;
  }
  .portrait-warning {
    display: block;
    text-align: center;
    margin-top: 50vh;
    transform: translateY(-50%);
    font-size: 1.5em;
  }
}


/* ====Placeholder css of input field=== */
.custom-placeholder::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1C1C1C;
}

.active-custom-placeholder::placeholder {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}


.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  display: block; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
  background-clip: content-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #E06F2C;
  border-radius: 8px;
}

/* Switch  */
.custom-switch .ant-switch-handle::before {
  background-color: rgba(224, 111, 44, 1) !important;
}

/*  remove the default blue background color of an input field that is auto-filled by the browser  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
